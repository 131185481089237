<template>
  <div class="body">
    <div class="body-head" @click="back">
      <img
        class="body-head-img"
        src="@/assets/back-left.png"
        width="30px"
        height="30"
      />
      <div class="body-head-title">{{ courseName }}</div>
    </div>
    <div class="body-content">
      <div class="body-content-width">
        <div class="body-content-live">
          <div class="left-player" id="player-con"></div>
        </div>
        <div class="body-content-chat">
          <div class="chat-boxes" id="chatlist">
            <span class="body-content-content-alert">欢迎来到直播间~</span>
            <div
              v-for="(item, index) in msgs"
              :key="index"
              class="chat-boxes-list"
            >
              <span
                v-if="item.name != '' && item.name != undefined"
                class="chat-boxes-list-name"
                >{{ item.name }}：</span
              >
              <span v-else class="chat-boxes-list-name"
                >{{ item.msg }}{{ item.name }}</span
              >
              <span
                v-if="item.name != '' && item.name != undefined"
                class="chat-boxes-list-content"
                >{{ item.msg }}</span
              >
            </div>
          </div>
          <div class="body-content-speak">
            <el-input
              v-if="ban"
              type="textarea"
              autosize
              placeholder="你已被禁言"
              :disabled="true"
              class="body-content-msg"
            >
            </el-input>
            <el-input
              v-else
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="content"
              maxlength="200"
              class="body-content-msg"
            >
            </el-input>

            <el-button
              type="primary"
              round
              size="small"
              class="body-content-send"
              @click="sendMssage"
              >发送</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="bottom-content-title">{{ detail.data.courseName }}</div>
        <div class="bottom-content-text" v-html="detail.data.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import chatURL from "@/utils/api_host.js";
import { getLivePlay } from "@/api/course";
var _this;
import { Message } from "element-ui";
var interTime = undefined;
export default {
  data() {
    return {
      detail: {
        random: "",
        data: {
          courseName: "",
        },
      },
      token: "",
      content: "",
      msg: "",
      ban: false,
      msgs: [],
      userId: 0,
      player: null, // 播放视频实例
      isfinish: false, // 视频是否播放完成
      videoProgress: 0, //课程视频的进度
      showChat: true,
      one: true,
      socket: null,
      courseName: "",
      liveId: ""
    };
  },
  mounted() {
    _this = this;
    this.detail = this.$route.query;
    if (typeof(_this.detail.data) == "string") {
      this.liveId = localStorage.getItem("liveId");
      this.courseName = localStorage.getItem("liveName");
      this.descr = localStorage.getItem("liveDescr");
      this.cover = localStorage.getItem("liveCover");
    } else {
      this.liveId = _this.detail.data.id
      this.courseName = _this.detail.data.name
      this.descr = _this.detail.data.descr
      this.cover = _this.detail.data.cover
      localStorage.setItem("liveId", _this.detail.data.id);
      localStorage.setItem("liveName",_this.detail.data.name)
      localStorage.setItem("liveCover",_this.detail.data.cover)
      localStorage.setItem("liveDescr",_this.detail.data.cover)
    }
    var time = _this.getCurrentTime();
    if (_this.compare(_this.detail.data.startTime, time)) {
      _this.errortext = "直播未开始";
      _this.error = true;
      return;
    } else if (_this.compare(time, _this.detail.data.endTime)) {
      _this.errortext = "直播已结束";
      _this.error = true;
      return;
    }
    this.live();
    if (typeof WebSocket === "undefined") {
      // console.log("您的浏览器不支持socket");
    } else {
      this.initWebSoket();
    }
  },
  methods: {
    initWebSoket() {
      // 未登录禁止使用发言系统
      this.token = localStorage.getItem("web-token");
      _this.userId = localStorage.getItem("userId");
      // token / 用户类型 / 直播id / 用户id / 用户名
      var ws;
      if(this.token){
        ws = chatURL.chatURL + `${localStorage.getItem("web-token")}/2/${_this.liveId}/${_this.userId}/${decodeURIComponent(localStorage.getItem("name"))}/1`;
      } else {
        ws = chatURL.chatURL + `0/2/${_this.liveId}/${this.getUUID()}/0/2`;
      }
      // 实例化socket
      this.socket = new WebSocket(ws);
      // 监听socket连接
      this.socket.onopen = function () {
        var info = JSON.stringify({ type: 0 });
        interTime = setInterval(() => {
          this.send(info);
        }, 5000);
      };
      // 监听socket错误信息
      this.socket.onerror = function (e) {
        // console.log("发生错误" + e.data);
      };
      // 监听socket消息
      this.socket.onmessage = async function (e) {
        var data = JSON.parse(e.data);
        if (data.type === 0 || data.type === 1) {
          var msg = {
            name: data.name,
            msg: data.msg,
            id: data.id,
          };
          if (data.type !== 0) {
            _this.msgs.push(msg);
          }
          if (_this.one) {
            // 进来的时候需要判断自己是否被禁言
            if (data.chatUsers !== undefined && data.chatUsers.length > 0) {
              data.chatUsers.forEach((item, index) => {
                if (item.id == _this.userId && item.forbid == 0) {
                  _this.$message.error("你已被禁言");
                  _this.ban = true;
                }
              });
            }
            _this.one = false;
          }
        } else if (data.type == 2) {
          // 被禁言
          var chatUsers = data.chatUsers;
          if (data.status == 0 && chatUsers == undefined) {
            // 被禁言
            _this.$message.warning("你已被禁言");
            _this.ban = true;
            _this.msgs.push({ msg: "全体禁言已开启" });
          } else if (data.status == 1 && chatUsers == undefined) {
            // 禁言撤销
            _this.$message.warning("禁言已撤销");
            _this.msgs.push({ msg: "全体禁言已关闭" });
            _this.ban = false;
          } else if (chatUsers != undefined) {
            if (chatUsers.length > 0) {
              chatUsers.forEach((item, index) => {
                if (item.forbid == 0) {
                  if (item.id == _this.userId) {
                    _this.$message.warning("你已被禁言");
                    _this.ban = true;
                    // _this.msgs.push({ msg: item.name + "已被禁言" });
                  } else {
                    // _this.msgs.push({ msg: item.name + "已被禁言" });
                  }
                } else {
                  // 恢复聊天
                  if (item.id == _this.userId) {
                    _this.$message.warning("禁言已撤销");
                    _this.ban = false;
                    // _this.msgs.push({ msg: item.name + "已恢复发言" });
                  } else {
                    // _this.msgs.push({ msg: item.name + "已恢复发言" });
                  }
                }
              });
            }
          }
        } else if (data.type == 3) {
          // type == 3 为信息被删除
          _this.msgs.forEach(function (item, index) {
            // 删除掉相同id的信息
            if (item.id == data.delMsgId) {
              _this.msgs.splice(index, 1);
            }
          });
        } else {
          // type == 4 账号被他人登录时返回的消息类型
          _this.loginOut();
        }
        // var chatlist = document.getElementById("chatlist");
        // chatlist.scrollTop = chatlist.scrollHeight;
        var sh = document.getElementById("chatlist").scrollHeight;

        var st = document.getElementById("chatlist").scrollTop;

        var ih = document.getElementById("chatlist").clientHeight;

        if (sh > ih) {
          var dif = sh - ih + 10;
          document.getElementById("chatlist").scrollTop = dif;
        }
      };
    },
    getUUID() {
      function S4() {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
		},
    /**
     * 直播
     */
    live() {
      getLivePlay({ random: this.detail.random, id: _this.liveId }).then(
        (res) => {
          if (res != undefined) {
            // 绑定id，播放视频
            this.player = new Aliplayer(
              {
                id: "player-con",
                source: res.data,
                width: "1200px",
                height: "800px",
                format: "m3u8",
                autoplay: true, // 自动播放
                isLive: true, // 直播
                rePlay: false, // 视频重播
                playsinline: true, //	H5是否内置播放，有的Android浏览器不起作用。
                preload: true, // 播放器自动加载，目前仅H5可用。
                enableStashBufferForFlv: true, // H5播放FLV时，设置是否启用播放缓存，只在直播下起作用。
                stashInitialSizeForFlv: 32, // H5播放FLV时，初始缓存大小，只在直播下起作用。
                controlBarVisibility: "hover",
                useH5Prism: true, // 指定使用H5播放器。
                cover: _this.cover,
              },
              function (player) {
                // 相关api查看：https://help.aliyun.com/document_detail/125572.htm?spm=a2c4g.11186623.0.0.797710e4KeHz9o#task-1997027
                // 监听播放事件
                player.on("playing", function () {
                  // console.log("正在播放");
                  _this.isfinish = true;
                  _this.videoProgress = player.getCurrentTime();
                });
                player.on("ended", function () {
                  // console.log("播放完毕");
                  _this.isfinish = false;
                });
                player.on("error", function () {
                  // console.log("播放异常");
                });
              }
            );
          }
        }
      );
    },
    send(info) {
      this.socket.send(info);
    },
    loginOut() {
      Message.error("该账号已在其它地方登录");
      this.socket.close();
      this.$router.push("/login");
    },
    login() {
      this.$router.push("login");
    },
    close() {
      // console.log("socket已经关闭");
    },
    //获取当前时间并打印
    getCurrentTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      var gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      return gettime;
    },
    // 比较日期大小
    compare(date1, date2) {
      let dates1 = new Date(date1);
      let dates2 = new Date(date2);
      if (dates1 > dates2) {
        return true;
      } else {
        return false;
      }
    },
    sendMssage() {
      // 登录后才能发言
      if (
        _this.token != null &&
        _this.token != undefined &&
        _this.token != "" &&
        _this.token != "null"
      ) {
        if (_this.ban) {
          _this.$message.warning("你已被禁言");
          return;
        } else if (_this.content == "") {
          _this.$message.warning("请输入消息");
          return;
        }
        this.send(JSON.stringify({ type: 1, msg: _this.content }));
        _this.content = "";
      } else {
        _this.$message.warning("请登录后使用该功能");
      }
    },
    back() {
      _this.$router.go(-1);
    },
  },
  beforeDestroy() {
    // 页面销毁时关闭websoket
    if (this.socket) {
      clearInterval(interTime);
      this.socket.close();
    }
    // 离开页面添加观看记录
  },
};
</script>

<style lang="scss" scoped>
body {
  width: 100%;
  height: 100%;
  background-color: #f4f5f8;
}
.body-content {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 160px;
  background-color: #f4f5f8;
  .body-content-width {
    width: 1700px;
    margin: 0 auto;
    display: flex;
    .body-content-live {
      width: 1200px;
      height: auto;
      border-radius: 5px;
      background-color: black;
      .left-player {
        width: 100%;
        height: 800px;
        border-radius: 5px 5px 0px 0px;
      }
      .left-name {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        margin: 20px 0 0 30px;
      }
      .left-bottom {
        margin: 20px 0 0 30px;
      }
    }
    .body-content-chat {
      width: 460px;
      height: 760px;
      margin-left: 10px;
      background: #fcfcfc;
      border-radius: 5px;
      padding: 20px;
      position: relative;
      .body-content-content {
        width: 100%;
        height: 94%;
        font-size: 16px;
        font-weight: 400;
        .body-content-content-alert {
          color: #4394ff;
          margin-bottom: 5px;
        }
        .body-content-content-msg {
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px;
          .name {
            color: #989898;
          }
          .msg {
            line-height: 22px;
            color: #545454;
          }
        }
      }
      .body-content-speak {
        position: absolute;
        bottom: 20px;
        display: flex;
        .body-content-msg {
          width: 384px;
          margin-right: 7px;
        }
        .body-content-send {
          width: 74px;
          height: 36px;
        }
      }
    }
  }
}
.bottom-content {
  width: 1645px;
  margin: 10px auto;
  padding: 20px 30px;
  font-weight: 400;
  background: #ffffff;
  .bottom-content-title {
    font-size: 20px;
    color: #000000;
  }
  .bottom-content-text {
    font-size: 16px;
    color: #b1b1b1;
  }
}
.body-head {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  display: -webkit-inline-box;
  line-height: 100px;
  cursor: pointer;
  .body-head-title {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
  }
  .body-head-img {
    margin-top: 34px;
    margin-left: 110px;
    margin-right: 20px;
  }
}
.body-content-content-alert {
  color: #4394ff;
  margin-bottom: 5px;
}
.chat-boxes {
  padding: 20px 20px 20px 0;
  height: 88%;
  overflow-y: scroll;
  .chat-boxes-list {
    font-size: 16px;
    padding: 5px 0px;
    text-align: left;
    word-wrap: break-word;
    .chat-boxes-list-name {
      color: #438eff;
    }
    .chat-boxes-list-content {
      font-weight: 400;
    }
  }
}
.chat-boxes-2 {
  padding: 20px 20px 20px 0;
  text-align: center;
  height: 88%;
  height: 650px;
  line-height: 650px;
  .chat-boxes-login {
    cursor: pointer;
    color: #438eff;
    font-size: 22px;
    font-weight: 400;
  }
  .chat-boxes-text {
    font-size: 22px;
    font-weight: 400;
    color: #000000;
  }
  .chat-boxes-list {
    font-size: 16px;
    padding: 5px 0px;
    text-align: left;
    word-wrap: break-word;
    .chat-boxes-list-name {
      color: #438eff;
    }
    .chat-boxes-list-content {
      font-weight: 400;
    }
  }
}
</style>